<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
})

console.error(props.error)

const { lenis } = useLenis()
const route = useRoute()

provide('lenis', lenis)

watch(
  () => route.name,
  () => {
    lenis.value.scrollTo(0, { immediate: true })
  },
)
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="page page-error row-175-20 p-top-2 p-bot-1">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-html="
            props.error.statusCode === 500
              ? $t('errors.500.text')
              : $t('errors.404.text')
          "
        />
        <br />
        <nuxt-link
          :to="
            localePath({
              name: 'index',
            })
          "
          class="underline-animation"
          >Torna alla Homepage</nuxt-link
        >
      </div>
    </NuxtLayout>
  </div>
</template>
